@import 'external.scss';
@import 'theme.module.scss';
@import 'mixins';

@font-face {
  font-family: 'Inter';
  src: url('/static/fonts/Inter-Regular.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/static/fonts/Inter-SemiBold.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: 'Inconsolata-Regular';
  src: url('/static/fonts/Inconsolata-Regular.ttf');
}

@font-face {
  font-family: 'Inconsolata-Bold';
  src: url('/static/fonts/Inconsolata-Bold.ttf');
}

body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: auto hidden;
  font-family: 'Inter', sans-serif;
}

body {
  /* this disables accidental refresh */
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  overflow: hidden;
}

strong {
  font-weight: 600;
}

input {
  -webkit-transform: translate3d(0, 0, 0);
}
ul {
  padding: 0;
}
li {
  list-style: none;
}

.text {
  color: get_color('primary', 0);
  font-size: 12px;

  font-family: 'Inter', sans-serif;

  &.large {
    font-size: 14px;
  }
  &.extra-large {
    font-size: 16px;
  }
  &.header {
    font-size: 18px;
  }
  &.small-header {
    font-size: 17px;
  }
  &.huge {
    font-size: 20px;
  }
  &.medium {
    font-size: 12px;
  }
  &.small {
    font-size: 11px;
  }

  &.size24 {
    font-size: 24px;
  }

  &.size22 {
    font-size: 22px;
  }

  &.size27 {
    font-size: 27px;
  }

  &.size40 {
    font-size: 40px;
  }

  &.italic {
    font-style: italic;
  }

  &.inherit-color {
    color: inherit;
  }

  &.procore {
    color: get_color('secondary', 50);
  }

  &.primary {
    color: get_color('neutral', 50);
  }

  &.dark-primary {
    color: get_color('primary', 70);
  }

  &.grey {
    color: get_color('neutral', 60);
  }
  &.grey-light {
    color: get_color('neutral', 50);
  }
  &.grey-lighter {
    color: get_color('neutral', 84);
  }
  &.grey-30 {
    color: get_color('neutral', 30);
  }
  &.grey-10 {
    color: get-color('neutral', 10);
  }
  &.brand-dark {
    color: get_color('primary', 0);
  }
  &.dark-blue {
    color: get_color('primary', 30);
  }
  &.blue {
    color: get_color('primary', 30);
  }
  &.blue-brand {
    color: get_color('primary', 40);
  }
  &.light-blue {
    color: get_color('primary', 70);
  }

  &.blue-highlight {
    color: get_color('primary', 50);
  }

  &.primary-blue {
    color: get_color('primary', 30);
  }
  &.white {
    color: get_color('neutral', 100);
  }
  &.red {
    color: get_color('error', 50);
  }
  &.orange {
    color: get_color('secondary', 40);
  }
  &.green {
    color: get_color('success', 70);
  }
  &.light-green {
    color: get_color('success', 30);
  }
  &.success-green {
    color: get_color('success', 40);
  }
  &.gold {
    color: get_color('secondary', 50);
  }
  &.banner-gold {
    color: get_color('warning', 70);
  }
  &.faded {
    color: get_color('neutral', 70);
  }
  &.light {
    color: get_color('neutral', 50);
  }
  &.secondary-orange {
    color: get_color('secondary', 40);
  }
  &.yellow {
    color: get_color('secondary', 60);
  }
  &.dark-gray {
    color: get_color('grey', 40);
  }

  &.no-bold {
    font-weight: 400;
  }

  &.medium-bold {
    font-weight: 500;
  }

  &.bold {
    font-weight: 600;
  }

  &.extra-bold {
    text-shadow: 1px 0 0;
  }
  &.underline {
    text-decoration: underline;
  }

  &.white-space-normal {
    white-space: normal;
  }

  &.bold-font {
    font-weight: 600;
  }

  &.right {
    text-align: right;
  }
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.middle {
    height: inherit;
    display: inline-flex;
    align-items: center;
  }

  &.end {
    text-align: end;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.lowercase {
    text-transform: lowercase;
  }

  &.capitalize {
    text-transform: capitalize;
  }

  &.transform-none {
    text-transform: none;
  }

  &.white-back {
    background-color: get_color('neutral', 100);
  }

  &.gray-back {
    background-color: get_color('neutral', 50);
  }

  &.line-large {
    line-height: 18px;
  }

  &.line-extra-large {
    line-height: 20px;
  }

  &.line-16 {
    line-height: 16px;
  }

  &.line-24 {
    line-height: 24px;
  }

  &.line-26 {
    line-height: 26px;
  }

  &.line-28 {
    line-height: 28px;
  }

  &.line-29 {
    line-height: 29px;
  }

  &.line-33 {
    line-height: 3px;
  }

  &.line-40 {
    line-height: 40px;
  }

  &.line-46 {
    line-height: 46px;
  }

  &.vertical {
    writing-mode: vertical-rl;
  }

  &.monospace {
    font-family: 'Inconsolata-Regular', monospace;

    // +1 regarding ProximaNova
    font-size: 13px;

    &.large {
      font-size: 15px;
    }

    &.extra-large {
      font-size: 17px;
    }

    &.bold {
      font-family: 'Inconsolata-Bold', monospace;
    }
  }
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.underline-hover:hover {
  text-decoration: underline;
}

.row {
  display: flex;
  align-items: center;
}
.row.reverse {
  flex-direction: row-reverse;
}
.row.align-start {
  align-items: flex-start;
}
.row.align-start.gap-8 {
  gap: 8px;
}
.row.x-start {
  justify-content: flex-start;
}
.row.x-center {
  justify-content: center;
}
.row.x-end {
  justify-content: flex-end;
}
.row.x-between {
  justify-content: space-between;
}
.row.x-evenly {
  justify-content: space-evenly;
}
.row.x-around {
  justify-content: space-around;
}
.row.y-center {
  align-items: center;
}
.row.y-start {
  align-items: flex-start;
}
.row.y-end {
  align-items: flex-end;
}
.row.y-stretch {
  align-items: stretch;
}
.row.y-baseline {
  align-items: baseline;
}
.row > * {
  flex: 1;
}
.row.wrap {
  flex-wrap: wrap;
}
.row.wrap.row-gap-4 {
  row-gap: 4px;
}
.row.no-flex-children * {
  flex: none;
}
.row.no-flex-children-direct > * {
  flex: none;
}
.row.unset-flex-children-direct > * {
  flex: unset;
}
.col {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.col.row {
  flex-direction: row;
}

.col.align-start {
  align-items: flex-start;
}

.col.justify-start {
  justify-content: flex-start;
}

.col.x-center {
  align-items: center;
}
.col.x-end {
  align-items: flex-end;
}
.col.y-center {
  justify-content: center;
}
.col.y-around {
  justify-content: space-around;
}
.col.y-end {
  justify-content: flex-end;
}
.col.y-between {
  justify-content: space-between;
}
.col.x-stretch {
  align-items: stretch;
}
.no-resize {
  resize: none;
}
.y-resize {
  resize: vertical;
}
.no-grow {
  flex-grow: 0 !important;
}
.flex-grow2 {
  flex-grow: 2 !important;
}
.flex-grow3 {
  flex-grow: 3 !important;
}
.no-white-space-wrap {
  white-space: nowrap;
}
.no-flex {
  flex: none !important;
}
.flex-unset {
  flex: unset !important;
}
.flex-item {
  flex: 1;
}
.flex-pull-right {
  margin-left: auto;
}
.flex-pull-left {
  margin-right: auto;
}

.full-height {
  height: 100% !important;
}

.height-fit-content {
  height: fit-content !important;
}

.no-background {
  background: none;
}

.no-width {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
}

.full-width {
  width: 100% !important;
}

.fill-available-width {
  min-width: -webkit-fill-available;
  max-width: -webkit-fill-available;
}

.x-scroll-hidden {
  overflow-x: hidden !important;
}

.y-scroll-hidden {
  overflow-y: hidden !important;
}

.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.static {
  position: static;
}

.absolute-right {
  position: absolute;
  right: 0;
}

.absolute-top {
  position: absolute;
  top: 0;
}

.top0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.bottom-0 {
  bottom: 0;
}

.fixed {
  position: fixed !important;
}

.fixed-modal-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 102;
  &.dark-background {
    background-color: get_color('primary', 0, 0.38);
  }
}

.fixed-modal-base {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  background-color: get_color('neutral', 100);
  z-index: 100;
  border-radius: 8px;
  box-shadow: 0 11px 15px 0 get_color('primary', 0, 0.2),
    0 9px 46px 0 get_color('primary', 0, 0.12),
    0 24px 38px 0 get_color('primary', 0, 0.14);
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.absolute-block {
  position: absolute;
  left: 0;
  right: 0;

  &-center {
    top: 50%;
    left: 50%;
  }

  &-x-center {
    left: 50%;
  }

  &-y-center {
    top: 50%;
  }
}

.relative-block {
  position: relative;
  height: 100%;
}

.circle-container {
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  width: 10px;
  height: 10px;
  background-color: get_color('neutral', 100);
  border-radius: 50%;
  border: 1px solid get_color('neutral', 70);
}

.category-of-variance-icon,
.safety-hazard-icon {
  rect {
    fill: get_color('neutral', 100);
  }
}

.category-of-variance-icon.inactive {
  g {
    fill: get_color('neutral', 50);
  }
}

.safety-hazard-icon.inactive {
  path {
    fill: get_color('neutral', 50);
  }
}

.item-buttons-container {
  padding: 16px 0;
  display: flex;
  justify-content: center;
}

.item-button {
  background-color: get_color('neutral', 100);
  border: 1px solid get_color('primary', 20);
  font-weight: 600;
  font-size: 14px;
  border-radius: 2px;
  color: get_color('primary', 20);
  border: 1px solid;
  flex: none;
  margin: 0 10px;

  &.close-btn {
    width: 90px;
    height: 28px;
    white-space: nowrap;

    &.action-bar {
      padding: 0;
      border: none;
      font-size: 16px;

      &.rfi {
        color: get_color('error', 50);
        path {
          fill: get_color('error', 50);
        }
      }

      &.flag {
        color: get_color('error', 50);
        path {
          fill: get_color('error', 50);
        }
      }

      &.schedule-comment {
        color: get_color('error', 50, 1);
        path {
          fill: get_color('error', 50, 1);
        }
      }

      svg {
        height: 11px;
      }
    }
  }

  &.reply-btn {
    width: 90px;
    height: 28px;
  }

  &.view-btn {
    width: 71px;
    height: 28px;
    display: flex;
    justify-content: space-around;
  }

  .close-button-img {
    width: 10px;
    margin-left: 5px;
  }
}

.half-flex-basis {
  flex-basis: 50%;
}

.full-flex-basis {
  flex-basis: 100%;
}

.flex-basis108 {
  flex-basis: 108px;
}

.flex-basis300 {
  flex-basis: 300px;
}

// From style guide, unused
.button {
  height: 28px;
  padding: 10px;
  border-radius: 2px;
}

.padding {
  &.p15 {
    padding: 15px;
  }
}

.pa1 {
  padding: 1px;
}
.pa2 {
  padding: 2px;
}

.pa3 {
  padding: 3px;
}

.pa4 {
  padding: 4px;
}
.pa5 {
  padding: 5px;
}
.pa8 {
  padding: 8px;
}
.pa15 {
  padding: 15px;
}

.pa10 {
  padding: 10px;
}
.pa12 {
  padding: 12px;
}
.pa20 {
  padding: 20px;
}

.pa24 {
  padding: 24px;
}

.pa30 {
  padding: 30px;
}

.pr4 {
  padding-right: 4px;
}
.pr5 {
  padding-right: 5px;
}

.pr6 {
  padding-right: 6px;
}

.pr8 {
  padding-right: 8px;
}
.pr10 {
  padding-right: 10px;
}
.pr12 {
  padding-right: 12px;
}
.pr15 {
  padding-right: 15px;
}
.pr16 {
  padding-right: 16px;
}
.pr17 {
  padding-right: 17px;
}
.pr20 {
  padding-right: 20px;
}

.pr24 {
  padding-right: 24px;
}

.pr25 {
  padding-right: 25px;
}

.pr50 {
  padding-right: 50px;
}

.pt1 {
  padding-top: 1px !important;
}

.pt2 {
  padding-top: 2px;
}

.pt3 {
  padding-top: 3px;
}

.pt4 {
  padding-top: 4px;
}

.pt5 {
  padding-top: 5px;
}

.pt10 {
  padding-top: 10px;
}

.pt12 {
  padding-top: 12px;
}

.pt15 {
  padding-top: 15px;
}

.pt20 {
  padding-top: 20px;
}

.pt28 {
  padding-top: 28px;
}

.pt30 {
  padding-top: 30px;
}

.pt24 {
  padding-top: 24px;
}

.pt30 {
  padding-top: 30px;
}

.pt50 {
  padding-top: 50px;
}

.pb4 {
  padding-bottom: 4px;
}

.pb5 {
  padding-bottom: 5px;
}

.pb8 {
  padding-bottom: 8px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb12 {
  padding-bottom: 12px;
}

.pb15 {
  padding-bottom: 15px;
}

.pb18 {
  padding-bottom: 18px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb24 {
  padding-bottom: 24px;
}

.pb40 {
  padding-bottom: 40px;
}

.pb50 {
  padding-bottom: 50px;
}

.pl2 {
  padding-left: 2px;
}

.pl4 {
  padding-left: 4px;
}

.pl5 {
  padding-left: 5px;
}

.pl6 {
  padding-left: 6px;
}

.pl7 {
  padding-left: 6px;
}

.pl8 {
  padding-left: 8px;
}

.pl12 {
  padding-left: 12px;
}

.pl15 {
  padding-left: 15px;
}

.pl16 {
  padding-left: 16px;
}

.pl17 {
  padding-left: 17px;
}

.pl20 {
  padding-left: 20px;
}

.pl24 {
  padding-left: 24px;
}

.pl25 {
  padding-left: 25px;
}

.pl30 {
  padding-left: 30px;
}

.pl35 {
  padding-left: 35px;
}

.pl36 {
  padding-left: 36px;
}

.pl40 {
  padding-left: 40px;
}

.px4 {
  padding-left: 4px;
  padding-right: 4px;
}
.px5 {
  padding-left: 5px;
  padding-right: 5px;
}
.px6 {
  padding-left: 6px;
  padding-right: 6px;
}
.px5-left {
  padding-left: 5px;
}
.px5-right {
  padding-right: 5px;
}

.px8 {
  padding-left: 8px;
  padding-right: 8px;
}

.px10 {
  padding-left: 10px;
  padding-right: 10px;
}

.px12 {
  padding-left: 12px;
  padding-right: 12px;
}

.px15 {
  padding-left: 15px;
  padding-right: 15px;
}

.px16 {
  padding-left: 16px;
  padding-right: 16px;
}

.px20 {
  padding-left: 20px;
  padding-right: 20px;
}

.px24 {
  padding-left: 24px;
  padding-right: 24px;
}

.px28 {
  padding-left: 28px;
  padding-right: 28px;
}

.px30 {
  padding-left: 30px;
  padding-right: 30px;
}

.px50 {
  padding-left: 50px;
  padding-right: 50px;
}

.py2 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.py3 {
  padding-top: 3px;
  padding-bottom: 3px;
}

.py4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.py5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py6 {
  padding-top: 6px;
  padding-bottom: 6px;
}

.py7 {
  padding-top: 7px;
  padding-bottom: 7px;
}

.py8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.py10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.py12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.py15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.py20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.py30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.py40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.ma0 {
  margin: 0 !important;
}

.ml2 {
  margin-left: 2px;
}

.m5 {
  margin: 5px;
}

.m10 {
  margin: 10px;
}

.m15 {
  margin: 15px;
}
.m20 {
  margin: 20px;
}

.mb0 {
  margin-bottom: 0;
}

.mb4 {
  margin-bottom: 4px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb8 {
  margin-bottom: 8px;
}

.mb10 {
  margin-bottom: 10px;
}
.ml10 {
  margin-left: 10px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb20 {
  margin-bottom: 20px;
}

.mb24 {
  margin-bottom: 24px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.ml-auto {
  margin-left: auto !important;
}

.ml12 {
  margin-left: 15px;
}

.ml15 {
  margin-left: 15px;
}

.ml20 {
  margin-left: 20px;
}

.ml25 {
  margin-left: 25px;
}

.ml28 {
  margin-left: 28px;
}

.ml30 {
  margin-left: 30px;
}

.ml35 {
  margin-left: 35px;
}

.ml40 {
  margin-left: 40px;
}

.ml50 {
  margin-left: 50px;
}

.mr30 {
  margin-right: 30px;
}

.mr35 {
  margin-right: 35px;
}

.mr4 {
  margin-right: 4px;
}

.mr6 {
  margin-right: 8px;
}

.mr8 {
  margin-right: 8px;
}

.mr12 {
  margin-right: 12px;
}

.mr28 {
  margin-right: 28px;
}

.mr50 {
  margin-right: 50px;
}

.mr20 {
  margin-right: 20px;
}

.mr-auto {
  margin-right: auto;
}

.mt4 {
  margin-top: 4px;
}

.mt5 {
  margin-top: 5px;
}
.mt8 {
  margin-top: 8px;
}
.mt10 {
  margin-top: 10px;
}
.mt14 {
  margin-top: 15px;
}
.mt15 {
  margin-top: 15px;
}
.mt20 {
  margin-top: 20px;
}
.mt25 {
  margin-top: 25px;
}
.mt30 {
  margin-top: 30px;
}
.mt40 {
  margin-top: 40px;
}
.mt50 {
  margin-top: 50px;
}

.mt100 {
  margin-top: 100px;
}

.my2 {
  margin-top: 2px;
  margin-bottom: 2px;
}

.my3 {
  margin-top: 3px;
  margin-bottom: 3px;
}

.my5 {
  margin: 5px 0;
}

.my8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.my10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my12 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.my20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my24 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.mx2 {
  margin: 0 2px;
}

.mx4 {
  margin: 0 4px;
}

.mx5 {
  margin: 0 5px !important;
}

.mx6 {
  margin: 0 6px;
}

.mx8 {
  margin-left: 8px;
  margin-right: 8px;
}

.mx10 {
  margin: 0 10px;
}

.mx12 {
  margin-left: 12px;
  margin-right: 12px;
}

.mx16 {
  margin-left: 16px;
  margin-right: 16px;
}

.mx24 {
  margin-left: 24px;
  margin-right: 24px;
}

.mx15 {
  margin-left: 15px;
  margin-right: 15px;
}

.mx20 {
  margin-left: 20px;
  margin-right: 20px;
}

.mx30 {
  margin-left: 30px;
  margin-right: 30px;
}

.ma12 {
  margin: 12px;
}

.ma-auto {
  margin: auto;
}

.ma-x-auto {
  margin: 0 auto;
}

.opacity3 {
  opacity: 0.3;
}

.opacity4 {
  opacity: 0.4 !important;
}

.opacity5 {
  opacity: 0.5;
}

.opacity6 {
  opacity: 0.6;
}

.opacity7 {
  opacity: 0.7;
}

.bw-2 {
  border-width: 2px !important;
}

// BORDER ALL
.ba-transparent {
  border: 1px solid transparent;
}

.ba-light-blue {
  border: 1px solid get_color('primary', 50) !important;
}

.ba-palette-grey {
  border: 1px solid get_color('neutral', 70) !important;
}

.ba-grey-scale-light {
  border: 1px solid get_color('neutral', 50);
}
.ba-light-input-border {
  border: 1px solid get_color('neutral', 84);
}
.ba-light-cool-grey {
  border: 1px solid get_color('slate', 90);
}
.ba-red {
  border: 1px solid get_color('error', 50) !important;
}
.ba-light-grey {
  border: 1px solid get_color('grey', 84);
}
.ba-primary-blue {
  border: 1px solid get_color('primary', 30) !important;
}

.ba-grey {
  border: 1px solid get_color('neutral', 70);
}

.ba-black {
  border: 1px solid get_color('primary', 0);
}

.ba-procore {
  border: 1px solid get_color('secondary', 50);
}

.ba-palette-brand-lighter {
  border: 1px solid get_color('neutral', 84) !important;
}

.ba-palette-brand-lightest {
  border: 1px solid get_color('neutral', 96);
}

.ba-palette-brand-light {
  border: 1px solid get_color('neutral', 60);
}

.ba-palette-secondary-orange {
  border: 1px solid get_color('secondary', 40);
}

.bb-light-input-border {
  border-bottom: 1px solid get_color('neutral', 84);
}

.ba-none {
  border: none;
}

.ba-dashed-palette-grey {
  border: 1px dashed get_color('neutral', 84);
}

.ba-dashed-palette-red {
  border: 1px dashed get_color('error', 50);
}

.ba-grey-80 {
  border: 1px solid get_color('neutral', 80);
}

.ba2-transparent {
  border: 2px solid transparent !important;
}

.ba2-group-highlight {
  border: 2px solid get_color('primary', 50) !important;
}

.ba2-slate-light-hover:hover {
  border: 2px solid get_color('slate', 70) !important;
}

.ba-palette-brand-primary {
  border: 1px solid get_color('primary', 30) !important;
}

.ba2-palette-brand-primary {
  border: 2px solid get_color('primary', 30) !important;
}

.outline-procore {
  outline-color: get_color('secondary', 50);
}

.bl-light-cool-grey {
  border-left: 1px solid get_color('slate', 90);
}

// BORDER BOTTOM
.bb-input-border {
  border-bottom: 1px solid get_color('neutral', 70);
}
.bb-light-cool-grey {
  border-bottom: 1px solid get_color('slate', 90);
}

.bb-brand-dark {
  border-bottom: 1px solid get_color('primary', 0);
}

.bb2-brand-dark {
  border-bottom: 2px solid get_color('primary', 0);
}

.bb-light-grey {
  border-bottom: 1px solid get_color('grey', 84) !important;
}
.bb-dark-grey-border {
  border-bottom: 1px solid get_color('neutral', 70);
}
.bb-light-grey-dashed {
  background-image: linear-gradient(
    to right,
    get_color('grey', 84) 60%,
    transparent 0%
  );
  background-position: bottom;
  background-size: 16px 1px;
  background-repeat: repeat-x;
}
.bb-cool-grey {
  border-bottom: 1px solid get_color('grey', 90);
}
.bb-red {
  border-bottom: 1px solid get_color('error', 50);
}

.by-light-input-border {
  border-bottom: 1px solid get_color('neutral', 84);
  border-top: 1px solid get_color('neutral', 84);
}

.bb-primary-blue {
  border-bottom: 1px solid get_color('primary', 30);
}

.bb-palette-brand-lighter {
  border-bottom: 1px solid get_color('neutral', 84);
}

.bb-palette-grey {
  border-bottom: 1px solid get_color('neutral', 70);
}

.bb2-primary-blue {
  border-bottom: 2px solid get_color('primary', 30);
}

// BORDER TOP
.bt-light-input-border {
  border-top: 1px solid get_color('neutral', 84);
}

.bt-black {
  border-top: 1px solid get_color('primary', 0);
}

.bt-input-border {
  border-top: 1px solid get_color('neutral', 70);
}

.bt-light-cool-grey {
  border-top: 1px solid get_color('slate', 90);
}

.bt-light-grey {
  border-top: 1px solid get_color('grey', 84);
}

.bt-cool-grey {
  border-top: 1px solid get_color('grey', 90);
}

.bt-palette-brand-lighter {
  border-top: 1px solid get_color('neutral', 84);
}

.bt-palette-grey {
  border-top: 1px solid get_color('neutral', 70);
}

// BORDER RIGHT
.br-none {
  border-right: none !important;
}

.br-light-cool-grey {
  border-right: 1px solid get_color('slate', 90);
}

.br-light-input-border {
  border-right: 1px solid get_color('neutral', 84);
}

.br-white {
  border-right: 1px solid get_color('neutral', 100);
}

.br-light-cool-grey {
  border-right: 1px solid get_color('grey', 84);
}

.br-black {
  border-right: 1px solid get_color('primary', 0);
}

.br-light-grey {
  border-right: 1px solid get_color('grey', 84);
}

.br-primary-blue {
  border-right: 1px solid get_color('primary', 30);
}

.br-palette-brand-lighter {
  border-right: 1px solid get_color('neutral', 84);
}

.br-palette-grey {
  border-right: 1px solid get_color('neutral', 70);
}

// BORDER LEFT
.bl-none {
  border-left: none !important;
}

.bl-light-grey {
  border-left: 1px solid get_color('grey', 84);
}

.bl-white {
  border-left: 1px solid get_color('neutral', 100);
}

.bl-primary-blue {
  border-left: 1px solid get_color('primary', 30);
}

.bl-palette-grey {
  border-left: 1px solid get_color('neutral', 70);
}

.bl-palette-brand-lighter {
  border-left: 1px solid get_color('neutral', 84);
}

.bl-light-input-border {
  border-left: 1px solid get_color('neutral', 84);
}

// border radius all
.brada3 {
  border-radius: 3px;
}

.brada4 {
  border-radius: 4px;
}

.brada5 {
  border-radius: 5px;
}
.brada2 {
  border-radius: 2px;
}
.brada8 {
  border-radius: 8px;
}
.brada10 {
  border-radius: 10px;
}

.brada24 {
  border-radius: 24px;
}

.bradt8 {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.brls4 {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.brrs4 {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.br-rounded {
  border-radius: 50% !important;
}

.bg-inherit {
  background-color: inherit;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-color-blue-brand {
  background-color: get_color('primary', 40);
}
.bg-light-cool-grey {
  background-color: get_color('grey', 96);
}
.bg-cool-grey {
  background-color: get_color('grey', 90);
}
.bg-blue {
  background-color: get_color('primary', 30);
}
.bg-blue-bright {
  background-color: get_color('primary', 50);
}
.bg-blue-light {
  // TODO: Add to theme module
  background-color: hsla(211, 76%, 94%);
}
.bg-white {
  background-color: get_color('neutral', 100);
}
.bg-dark {
  background-color: get_color('primary', 0);
}
.bg-red {
  background-color: get_color('error', 50) !important;
}
.bg-pink {
  background-color: get_color('magenta', 96);
}

.bg-primary-blue {
  background-color: get_color('primary', 30);
}

.bg-blue-active {
  background-color: get_color('primary', 96);
}

.bg-blue-available {
  background-color: get_color('primary', 88);
}

.bg-grey {
  background-color: get_color('neutral', 70);
}

.bg-grey-scale-dark {
  background-color: get_color('primary', 0);
}

.bg-grey-scale-light {
  background-color: get_color('neutral', 50);
}

.bg-grey-scale-tooltip {
  background-color: get_color('neutral', 30);
}

.bg-palette-brand-lightest {
  background-color: get_color('neutral', 96);
}

.bg-palette-brand-light {
  background-color: get_color('neutral', 60);
}

.bg-palette-brand-lighter {
  background-color: get_color('neutral', 84);
}

.bg-neutral-92 {
  background-color: get_color('neutral', 92);
}

.bg-palette-blue-scale {
  background-color: get_color('primary', 50);
}

.bg-palette-green {
  background-color: get_color('green', 50) !important;
}

.bg-secondary-80 {
  background-color: get_color('secondary', 80);
}

.bg-orange-light {
  background-color: get_color('secondary', 84);
}

.bg-slate-88 {
  background-color: get_color('slate', 88);
}

.bg-grey-92 {
  background-color: get_color('neutral', 92);
}

.rotate180 {
  transform: rotate(180deg);
}

.blur-filter {
  filter: blur(1px);
}

.icon {
  height: 12px;
}

.icon-w11 svg {
  width: 11px;
}

.icon-w13 svg {
  width: 13px;
}

.icon-w20 svg {
  width: 20px;
}

.icon-red-g svg g {
  fill: get_color('error', 50);
}

.icon-red rect {
  fill: get_color('error', 50);
}

.icon-tomato rect {
  fill: get_color('error', 50);
}

.icon-gold rect {
  fill: get_color('secondary', 50);
}

.icon-grey rect {
  fill: get_color('neutral', 70);
}

.icon-yellow rect {
  fill: get_color('error', 50);
}

.icon-grey svg {
  fill: get_color('neutral', 70);
}

.icon-white-background svg {
  background: get_color('neutral', 100);
}

.h-auto {
  height: auto;
}

.h16 {
  height: 16px;
}
.h18 {
  height: 18px;
}
.h20 {
  height: 20px;
}
.h28 {
  height: 28px;
}
.h32 {
  height: 32px;
}
.h36 {
  height: 36px;
}
.h40 {
  height: 40px;
}
.h48 {
  height: 48px;
}
.h50 {
  height: 50px;
}
.h56 {
  height: 56px;
}
.h70 {
  height: 70px;
}
.h90 {
  height: 90px;
}
.h100 {
  height: 100px;
}
.h300 {
  height: 300px;
}
.h400 {
  height: 400px;
}

.w18 {
  width: 18px;
}
.w20 {
  width: 20px;
}
.w25 {
  width: 25px;
}
.w12 {
  width: 12px;
}
.w16 {
  width: 16px;
}

.w32 {
  width: 32px;
}
.w48 {
  width: 48px;
}

.w200 {
  width: 200px;
}

.w375 {
  width: 375px;
}

.w500 {
  width: 500px;
}
.w600 {
  width: 600px;
}

.w-max-content {
  min-width: max-content;
}
.w-fit-content {
  max-width: fit-content;
}
.mw-fit-content {
  min-width: fit-content;
}
.mw20 {
  min-width: 20px;
  max-width: 20px;
}
.mw30 {
  min-width: 30px;
  max-width: 30px;
}
.mw32 {
  min-width: 32px;
  max-width: 32px;
}
.mw35 {
  min-width: 35px;
  max-width: 35px;
}
.mw40 {
  min-width: 40px;
  max-width: 40px;
}
.mw55 {
  min-width: 55px;
  max-width: 55px;
}
.mw65 {
  min-width: 65px;
  max-width: 65px;
}
.mw75 {
  min-width: 75px;
  max-width: 75px;
}
.mw80 {
  min-width: 80px;
  max-width: 80px;
}
.mw90 {
  min-width: 90px;
  max-width: 90px;
}

.mw100 {
  min-width: 100px;
  max-width: 100px;
}

.mw120 {
  min-width: 120px;
  max-width: 120px;
}

.mw130 {
  min-width: 130px;
  max-width: 130px;
}

.mw140 {
  min-width: 140px;
  max-width: 140px;
}

.mw160 {
  min-width: 160px;
  max-width: 160px;
}

.mw180 {
  min-width: 180px;
  max-width: 180px;
}

.mw200 {
  min-width: 200px;
  max-width: 200px;
}

.mw240 {
  min-width: 240px !important;
  max-width: 240px !important;
}

.mw270 {
  min-width: 270px !important;
  max-width: 270px !important;
}

.mw300 {
  min-width: 300px !important;
  max-width: 300px !important;
}

.mw400 {
  min-width: 400px;
  max-width: 400px;
}

.mw590 {
  min-width: 590px;
  max-width: 590px;
}

.min-width0 {
  min-width: 0;
}

.min-width30 {
  min-width: 30px;
}

.min-height16 {
  min-height: 16px;
}

.min-height32 {
  min-height: 32px;
}

.min-height66 {
  min-height: 66px;
}

.min-height40 {
  min-height: 40px;
}

.min-height130 {
  min-height: 130px;
}

.max-height260 {
  max-height: 260px;
}

.max-height360 {
  max-height: 360px;
}

.max-width240 {
  max-width: 240px;
}

.max-width270 {
  max-width: 270px;
}

.max-h-w {
  max-height: 100%;
  max-width: 100%;
}

.not-visible {
  visibility: hidden;
}

.inline-flex {
  display: inline-flex !important;
}

.hidden {
  display: none !important;
}

.transparent {
  opacity: 0 !important;
}

.opaque {
  opacity: 1 !important;
}
.dummy-width {
  width: 0;
}

.grid {
  &-x-start {
    justify-self: start;
  }
  &-x-center {
    justify-self: center;
  }
  &-x-end {
    justify-self: end;
  }

  &-row-1 {
    grid-row-start: 1;
  }

  &-row-2 {
    grid-row-start: 2;
  }

  &-row-3 {
    grid-row-start: 3;
  }

  &-col-1 {
    grid-column-start: 1;
  }

  &-col-2 {
    grid-column-start: 2;
  }

  &-col-3 {
    grid-column-start: 3;
  }
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-unset {
  overflow: unset !important;
}

.overflow-auto {
  overflow: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-hidden-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
}

.nowrap {
  white-space: nowrap;
}

.pre-line {
  white-space: pre-line;
}

//text-ellipsis
.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.two-line-text-ellipsis {
  /* To save some time I didn't write a fallback, also
    seems like most of the browsers support this solution */
  @supports (-webkit-line-clamp: 2) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.bp3-button.primary-blue-btn {
  border: none;
  height: 32px;
  background-color: get_color('primary', 30);
  border-radius: 3px;
  color: get_color('neutral', 100);
  font-size: 14px;
  cursor: pointer;
  background-image: none;
  padding: 0;
  width: 100%;

  &:not(.bp3-disabled):hover {
    background-color: get_color('primary', 30);
    box-shadow: none;
  }
}

.bp3-button.white-button {
  background-color: get_color('neutral', 100);
  border: 1px solid get_color('primary', 30);
  color: get_color('primary', 30);
  background-image: none;
  box-shadow: none;
  padding: 7px 27px;

  &:hover {
    background-color: get_color('neutral', 100);
    box-shadow: none;
  }
}

.primary-blue-checkbox {
  padding: 0 !important;
  margin: 0;

  .bp3-control-indicator {
    background-color: get_color('neutral', 100) !important;
    border: 1px solid get_color('primary', 30);
    width: 14px;
    height: 14px;
    box-shadow: none !important;
    box-sizing: content-box;
    vertical-align: top;
    background-image: none;
    margin: 0 !important;
  }

  input:checked ~ .bp3-control-indicator {
    background-image: none;
    color: get_color('neutral', 100);
    box-shadow: none;

    &::before {
      width: 14px;
      height: 14px;
      position: absolute;
      border-radius: 3px;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAACmSURBVDhPY/hPJhhuGt9/+vF/2tor/3/8+gMVIULjxy+//psnr/vP5TD7/9FLz6GiSBpvP/7w3zJ1/f+Lt99ARVA1Ldh6EyoKAXCNtx59+C/utfC/pM+i/1fuvoVr4rTH1AQCKE49d/P1f2H3+WDNZklrwZpmbbgGlUUFGH48fOH5f0HXeWBNk1dfhopiAqyBc+jCs/+Lt9+C8rADrBqJAfTW+P8/AHle5x2PxHxpAAAAAElFTkSuQmCC') !important;
      top: 0;
    }
  }
}

.bp3-switch.primary-blue-switch {
  font-size: 14px;
  color: get_color('neutral', 50);

  .bp3-control-indicator {
    width: 30px;
    height: 20px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid get_color('neutral', 50);
    background-color: get_color('neutral', 92) !important;

    &::before {
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      top: -1px;
      left: -1px;
      background-color: get_color('neutral', 100);
      margin: 0;
      border-radius: 10px;
      border: 1px solid get_color('neutral', 50);
      background: url(/static/icons/close-grey.svg) no-repeat center center;
    }
  }

  input:checked ~ .bp3-control-indicator {
    background-image: none;
    background-color: get_color('violet', 40) !important;
    box-shadow: none;

    &::before {
      border-color: get_color('violet', 40);
      background: url(/static/icons/checked-blue.svg) no-repeat center center;
      background-color: get_color('neutral', 100);
      background-size: 15px;
      left: 0.6em;
    }
  }
}

.pl10 {
  padding-left: 10px;
}

.pt7 {
  padding-top: 7px;
}

.pt8 {
  padding-top: 8px;
}

.pb7 {
  padding-bottom: 7px;
}

.pb3 {
  padding-bottom: 3px;
}

.mr40 {
  margin-right: 40px;
}

.mr10 {
  margin-right: 10px;
}

.mr12 {
  margin-right: 12px;
}

.mr15 {
  margin-right: 15px;
}

.mr25 {
  margin-right: 25px;
}

.mr5 {
  margin-right: 5px;
}

.mr80 {
  margin-right: 80px;
}

.ml3 {
  margin-left: 3px;
}

.ml4 {
  margin-left: 4px;
}

.ml5 {
  margin-left: 5px;
}

.ml8 {
  margin-left: 8px;
}

.ml40 {
  margin-left: 40px;
}

.ml80 {
  margin-left: 80px;
}

.lp15 {
  letter-spacing: 1.5px;
}

.lp075 {
  letter-spacing: 0.75px;
}

.lp05 {
  letter-spacing: 0.5px;
}

.lpMinus05 {
  letter-spacing: -0.5px;
}

.lpMinus018 {
  letter-spacing: -0.18px;
}

.lpMinus01 {
  letter-spacing: -0.1px;
}

.lp015 {
  letter-spacing: 0.15px;
}

.z-index-1 {
  z-index: 1;
}

.z-index-5 {
  z-index: 5;
}

.z-index-10 {
  z-index: 10;
}

.z-index-100 {
  z-index: 100;
}

.z-index-120 {
  z-index: 120 !important;
}

.lp035 {
  letter-spacing: 0.35px;
}

.dashed-top {
  border-top-style: dashed !important;
  border-top-width: 3px !important;
}

.dashed-bottom {
  border-bottom-style: dashed !important;
  border-bottom-width: 3px !important;
}

.blue-checkbox-container {
  display: inline-block;
  width: 17px;
  height: 16px;
  vertical-align: middle;
  margin-right: 4px;
  overflow: hidden;
}

.no-outline {
  outline: none !important;
}

.no-outline-container * {
  outline: none !important;
}

.pointer {
  cursor: pointer;
}

.highlighted-hover:hover {
  background-color: get_color('neutral', 92);
}

.highlighted-hover-light:hover {
  background-color: get_color('neutral', 96);
}

.highlighted-active:active {
  background-color: get_color('neutral', 88);
  transition: background-color 0.2s ease;
}

.cross-cursor {
  cursor: url('/static/icons/close-black.svg') 5 5, auto;
}

.default-cursor {
  cursor: default !important;
}

.not-allowed {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.resize-cursor {
  cursor: col-resize;
}

.wait-cursor {
  cursor: wait;
  opacity: 0.5;
}

.help-cursor {
  cursor: help;
}

.text-cursor {
  cursor: text;
}

.grab-cursor {
  cursor: grab;
}

.inactive-element {
  pointer-events: none;
  opacity: 0.5;
}

.unclickable-element {
  pointer-events: none;
}

.clickable-element {
  pointer-events: all;
}

.force-clickable {
  pointer-events: auto;
}

.warning-icon {
  color: get_color('secondary', 40);
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.inline-block {
  display: inline-block !important;
}

.bp3-toast.primary-blue-theme * {
  color: get_color('primary', 30) !important;
}

.bp3-toast.error-theme * {
  color: get_color('error', 50) !important;
}

.inverted-colors {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

span.bp3-icon {
  flex: 0 0 auto;
}

.theme-radio-group {
  &.inline {
    display: inline-block;

    .bp3-control.bp3-radio {
      display: inline-block;
      margin-right: 5px;
    }
  }

  .bp3-control.bp3-radio {
    font-size: 16px;
    padding-top: 5px;
    color: get_color('primary', 0);

    &.small {
      font-size: 14px;
    }

    &.with-indent {
      padding-top: 20px;
    }

    .bp3-control-indicator {
      border: 1px solid get_color('primary', 30);
      box-shadow: none !important;
      background-color: get_color('neutral', 100) !important;
    }

    input:checked ~ .bp3-control-indicator:before {
      background-image: radial-gradient(
        get_color('primary', 30),
        get_color('primary', 30) 40%,
        transparent 40%
      );
      width: 14px;
      height: 14px;
      vertical-align: top;
    }
  }
}

.vertical-align-top {
  vertical-align: top;
}

.vertical-align-bottom {
  vertical-align: bottom;
}

.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-text-bottom {
  vertical-align: text-bottom;
}

.grey-checkbox {
  .p-checkbox-box.p-component {
    border-color: get_color('neutral', 50);
    color: get_color('neutral', 50);
  }
}

.grey-10-checkbox {
  .p-checkbox-box.p-component {
    border-color: get_color('neutral', 10);
    color: get_color('neutral', 10);
  }
}

.as-stretch {
  align-self: stretch;
}

.as-start {
  align-self: start;
}

.as-end {
  align-self: flex-end;
}

.no-border-color {
  border-color: transparent !important;
}

.bp3-tooltip:not([class*='bp3-intent']) {
  .bp3-popover-content {
    background: get_color('neutral', 30);
    color: get_color('neutral', 100);
  }

  .bp3-popover-arrow-border {
    fill: get_color('neutral', 30) !important;
  }

  .bp3-popover-arrow-fill {
    fill: get_color('neutral', 30) !important;
  }
}

.beautiful-shadow {
  box-shadow: 0 4px 5px 0 get_color('neutral', 84),
    0 1px 10px 0 get_color('neutral', 92),
    0 2px 4px -1px get_color('neutral', 70);
}

.beautiful-shadow-hover:hover {
  box-shadow: 0 4px 5px 0 get_color('neutral', 84),
    0 1px 10px 0 get_color('neutral', 92),
    0 2px 4px -1px get_color('neutral', 70);
  &:active,
  &:focus {
    box-shadow: none;
    transition: box-shadow 0.2s ease;
  }
}

.normalized-shadow {
  box-shadow: 0px 0px 20px get_color('neutral', 0, 0.5);
}

.modal-shadow {
  box-shadow: 0px 8px 10px get_color('neutral', 0, 0.14),
    0px 3px 14px get_color('neutral', 0, 0.12),
    0px 5px 5px get_color('neutral', 0, 0.2);
}

.bare-input {
  @include bare-input;
}

input[type='number'].bare-input-appearance::-webkit-inner-spin-button,
input[type='number'].bare-input-appearance::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
}

.bare-select-wrapper select {
  appearance: none;
  -webkit-appearance: none;
}

.modal-overlay {
  z-index: 99;
  background-color: get_color('neutral', 60, 0.4);
}

.indication-click {
  &:not(:active) {
    filter: opacity(1);
    transform: scale(1);
    transform: translate(0px, 0px);
    transition: all 100ms step-end;
  }

  // More subtle active state animation on mobile desktop
  &:active {
    filter: opacity(0.5);
    @media (max-width: 767px) {
      transform: scale(0.9);
    }
    @media (min-width: 768px) {
      transform: translate(2px, 2px);
    }
  }
}

.virtualized-list-smart-wrapper {
  flex: 1 1 auto;
  overflow: hidden;
}

.thumbnail-holder-image {
  width: auto;
  height: 50px !important;
  vertical-align: middle;
  line-height: 0;
}
.thumbnail-holder {
  transition: border 0.3s ease-out;
  border: 4px solid transparent;
  z-index: 1000;

  &:hover {
    outline: none;
    border: 4px solid get_color('primary', 50);
  }

  &.active {
    outline: none;
    border: 4px solid get_color('primary', 50);
  }
}

// fixes IOS issue for absolute elements inside scrolling containers
.ios-scrolling-container {
  -webkit-transform: translate3d(0, 0, 0);
}

.common-required-field {
  color: get_color('primary', 30) !important;
  font-weight: 600;
}

.bp3-portal {
  z-index: 100;
}

.word-break-all {
  word-break: break-all;
}

.react-tel-input {
  > .form-control {
    height: 40px !important;
    border-radius: 4px;
    font-size: 16px !important;
    padding: 8px 8px 8px 48px !important;
    background-color: get_color('neutral', 96) !important;

    &:focus {
      -webkit-appearance: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
  .flag-dropdown {
    background: none !important;
    padding-left: 8px !important;
    margin: 0 !important;
    border: none !important;

    > .selected-flag {
      padding: 0 !important;
      background: none !important;
    }

    &::before {
      display: none !important;
    }
  }
}

.theme-mode-switcher {
  &-icon {
    &.active {
      path {
        fill: get_color('violet', 50);
      }
    }
  }
  .bp3-control-indicator {
    margin-right: -26px !important;
  }
}

.user-name-container {
  &.bold {
    font-weight: 600;
  }
  &.clickable:hover {
    text-decoration: underline;
    color: get_color('primary', 50);
    font-weight: 600;
  }
  &.always-underlined {
    text-decoration: underline;
  }
}

.cursor-follow-tooltip {
  position: relative;
  z-index: 10;
}

.current-color {
  color: currentColor;
}

.p-checkbox {
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  margin-right: 0.25em;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.p-checkbox .p-checkbox-box {
  width: 1.125em;
  height: 1.125em;
  line-height: 1.125em;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  text-align: center;
}

.p-checkbox .p-checkbox-icon {
  display: block;
}

.p-checkbox-label {
  vertical-align: middle;
}

input.bare-file-input + .bp3-file-upload-input {
  @include bare-input;

  &::after {
    content: none;
  }
}

.attachment-upload-button {
  input {
    display: none;
  }

  .bp3-file-upload-input {
    position: relative !important;
  }
}

.display-on-hover-container {
  .display-on-hover {
    visibility: hidden;

    &.visible-always {
      visibility: visible !important;
    }
  }
  &:hover .display-on-hover {
    visibility: visible;
  }
}

.primary50-color-hover:hover {
  color: get_color('primary', 50);
}
