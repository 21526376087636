@import '~/client/src/shared/theme.module';

.projects-list.layout-view-root.col.full-height {
  // Hack to make the entire scrollable area visible
  min-height: 0;
}

.projects-mobile-component {
  .companies-container {
    padding-left: 18px;
    padding-right: 18px;

    &:focus-within {
      .active {
        border-bottom-color: get_color('neutral', 100);
      }
    }
  }
}
