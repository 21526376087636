@import '~/client/src/shared/theme.module';

.workflow-backlinks-popup {
  min-width: 150px;
  max-width: 300px;
  transform: translateX(-100%);
  box-shadow: 0px 1px 3px 0px get_color('neutral', 0, 0.3);
}

.workflow-backlinks {
  .trigger-icon {
    svg {
      width: 20px;
      height: 20px;
      vertical-align: middle;
      path {
        fill: get_color('primary', 30, 1);
        fill-opacity: 1;
      }
    }
  }
}
