@import '~/client/src/shared/theme.module';

.workflow-directory {
  .workflow-directory-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;

    &:hover {
      background-color: get_color('primary', 92, 0.5);
    }
    &.selected {
      background-color: get_color('primary', 92);
    }
  }
}
