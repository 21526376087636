@import '~/client/src/shared/theme.module';

.mapbox-popup {
  .sitemap-draggable-modal {
    min-width: 0;
  }
  .header {
    cursor: pointer;
  }
  .mapboxgl-popup-content {
    padding: 0;
  }
}
