.save-password-popup {
  .password-section {
    overflow: hidden;
    transition: opacity 0.2s ease-in, max-height 0.3s ease-in-out,
      margin-top 0.3s ease-in-out;

    &.show {
      max-height: 500px;
      opacity: 1;
      margin-top: 20px;
    }

    &.hide {
      max-height: 0;
      opacity: 0;
      margin-top: 0;
    }
  }
}
