@import '~/client/src/shared/theme.module';

.form-field-wrapper-input .incremental-search {
  $form-width: 100%;
  $results-margin-top: -20px;

  %preview-base {
    .name-row {
      font-size: 16px !important;
      color: get_color('primary', 0) !important;
    }

    .brief-info-row {
      font-size: 12px !important;
      color: get_color('neutral', 50) !important;
    }
  }

  .form-setup {
    &-result-list {
      width: $form-width;

      .member-preview {
        @extend %preview-base;
      }
    }

    &-search-input {
      width: $form-width;
    }

    &-search-input-result {
      position: absolute;
      width: $form-width;
      border-top: none;
      box-shadow: 10px 10px 15px get_color('neutral', 10, 0.4);
      outline: none;
      z-index: 100;
      margin-top: $results-margin-top;

      .result-row {
        .member-preview {
          @extend %preview-base;
        }

        &.current {
          background-color: get_color('primary', 92);
        }
      }
    }
  }
}
